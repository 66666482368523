import React from 'react'
import { Fade } from 'react-reveal'
import { Container, Flex, Link as A, Text } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  FaqHeader,
  CareerRow,
  CareerSectionLead,
  CorporateBackground
} from 'components/Content'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

const contentContainer = {
  maxWidth: 72,
  width: 1,
  p: 3,
  color: 'black',
  style: { position: 'relative' }
}
const content = { maxWidth: 48, mx: 0, color: 'black' }

A.link = A.withComponent(Link)

const title = 'Corporate Training- Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/corporate/"
    img="/cards/workshops.png"
  >
    <Nav />
    <Social />
    <CorporateBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[3, 4]} mb={[4, 5]} mx="auto">
            <Fade bottom>Corporate Training</Fade>
          </Headline>
        </Container>
      </Flex>
    </CorporateBackground>
    <Container {...contentContainer}>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>CORPORATE TRAINING</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Corporate training methodology helps to ensure your specific training
        and business requirements are addressed.The corporate training programs
        target both large enterprises,startups and medium businesses to bridge
        the competency gap towards building skilled and productive work force to
        meet the challenges of global business competitions by corporates. Our
        training solutions provide Corporates, a suite of best-in-class training
        processes that enable customers to reduce costs, sharpen their business
        focus.
      </CareerSectionLead>
      <Text fontSize={4} color="green.7">
        Our domain expertise lies in
      </Text>
      <CareerSectionLead mt={4}>
        • Web Development <br />
        • Mobile App Development <br />• Big Data <br />
        • Data Science <br />
        • Blockchain <br />
        • IoT – Internet of Things <br />• Cloud Computing
      </CareerSectionLead>
      <CareerSectionLead>
        We help the companies see tangible measurable results by continuously
        upgrading the capabilities and skills of their most important assets
        with the most in-demand training solutions and the main focus would be
        on productivity enhancement training. The ultimate goal of the training
        would be spurring excellence in employee performance at every level of
        the organization.
        <br />
        <br /> Coding Sastra’s learning works with clients to design and execute
        on highly effecting on boarding programs that accelerate business
        performance, organizational change and talent retention in the format
        that works best for your culture– whether instructor led or digital
        learning or a blend of several approaches.
      </CareerSectionLead>
    </Container>
    <Footer />
  </Layout>
)

export const query = graphql`
  query corporateMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
